import { Elm } from '../elm/src/Menu.elm'

document.addEventListener('DOMContentLoaded', (event) => {
  var sidemenuToggleOpen = document.querySelector('.sidemenu-toggle.when-open')
  var sidemenuToggleCollapsed = document.querySelector('.sidemenu-toggle.when-collapsed')
  var sidemenuToggledTitle = document.querySelector('#sidemenu-toggled-title')
  var sidemenu = document.querySelector('aside')

  if (sidemenuToggleOpen && sidemenuToggleCollapsed && sidemenuToggledTitle && sidemenu) {
    sidemenuToggleOpen.addEventListener('click', function (event) {
      sidemenuToggleOpen.classList.add('is-hidden')
      sidemenuToggleOpen.classList.remove('is-visible')
      sidemenuToggleCollapsed.classList.remove('is-hidden')
      sidemenuToggleCollapsed.classList.add('is-visible')
      // sidemenuToggledTitle.classList.remove('is-hidden')
      sidemenu.classList.remove('sidemenu-is-open')
      sidemenu.classList.add('sidemenu-is-closed')
      document.cookie = 'esy_sidemenuHidden=true; path=/; SameSite=strict;'
    })

    sidemenuToggleCollapsed.addEventListener('click', function (event) {
      // sidemenuToggledTitle.classList.add('is-hidden')
      sidemenuToggleCollapsed.classList.add('is-hidden')
      sidemenuToggleCollapsed.classList.remove('is-visible')
      sidemenuToggleOpen.classList.remove('is-hidden')
      sidemenuToggleOpen.classList.add('is-visible')
      sidemenu.classList.remove('sidemenu-is-closed')
      sidemenu.classList.add('sidemenu-is-open')
      document.cookie = 'esy_sidemenuHidden=false; path=/; SameSite=strict;'
    })
  }

  const app = Elm.Menu.init({
    node: document.getElementById('menu-list'),
    flags: window.ElmFlags.Menu
  })

  app.ports.initFailed.subscribe(message => {
    console.log(message)
    const err = new Error(message)
    err.name = 'Elm.Menu.Init error'
    window.onerror(err.name, window.location.href, 0, 0, err)
  })

  /* app.ports.pushUrl.subscribe(url => {
    history.replaceState({}, '', url)
  })

  app.ports.logError.subscribe(message => {
    console.log(message)
  })

  */
})
